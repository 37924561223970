// Librairies
import React from "react";
// import $ from 'jquery';
import { auth } from "@mitim/react-mitim";

export default class Page extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		auth.updateAuthorities();
	}
}
