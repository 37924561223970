import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";
import "./Home.scss";
import ModuleGallerie from "../ModuleGallerie/ModuleGallerie";

class Home extends Page {
	constructor(props) {
		super(props);
		this.state = { pageSite: [], website: [], load: false };
	}

	componentDidMount() {
		super.componentDidMount();
		api.getPageSite("accueil", this, () => this.setState({ load: true }));
	}

	componentDidUpdate() {
		let contentNode = document.querySelector(".contenu-page");
		if (this.state.pageSite.contenu != null) {
			contentNode.innerHTML = this.state.pageSite.contenu;
		}
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid pb-lg-5 no-gutters pt-5"
					style={{ minHeight: "480px", backgroundColor: "#fff" }}
				>
					<div className="container">
						<div className="eglises">
							<h1 className="text-center ft-lato-light">
								{t("home.qui-sommes-nous.titre").toUpperCase()}
							</h1>
							<p>
								<br />
							</p>
							<p style={{ textAlign: "justify" }}>
								{t("home.qui-sommes-nous.contenu")}
							</p>
							<p>
								<br />
							</p>
							<div className="contenu-page"></div>
						</div>
					</div>
				</div>

				{this.state.load && (
					<ModuleGallerie idGallerie={this.state.pageSite.id} />
				)}
			</>
		);
	}
}

export default withTranslation()(Home);
