import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";
import "./MediaList.scss";

class MediaList extends Page {
	constructor(props) {
		super(props);
		this.state = { pageSite: [], website: [], fichiers: [], videosYoutube: [] };
	}

	componentDidMount() {
		api.getPageSite("media", this);
		api.getVideoYoutubeOfPageSite("media", this);
		api.getFichiersOfPageSite("media", this);
	}

	videoYoutubeList() {
		const { t } = this.props;
		const listItems = this.state.videosYoutube.map(video => (
			<>
				{video.videoListResponse.items[0] && (
					<div className="col-sm-4" key={video.videoListResponse.items[0].id}>
						<p>
							<div className="video-container">
								<iframe
									title={video.videoListResponse.items[0].id}
									width={560}
									height={315}
									src={
										"https://www.youtube.com/embed/" +
										video.videoListResponse.items[0].id +
										"?rel=0"
									}
									frameBorder={0}
									allowFullScreen
									modestbranding={1}
									controls={0}
									rel={0}
									info={0}
								/>
							</div>
							<br />
							{video.videoListResponse.items[0].snippet.title}
							<br />
							<br />
						</p>
					</div>
				)}
			</>
		));
		return (
			<>
				{this.state.videosYoutube.length > 0 && (
					<>
						<h1 style={{ textAlign: "center" }}>
							{t("media.videos").toUpperCase()}
						</h1>
						<p>
							<br />
						</p>
					</>
				)}
				<div className="row">{listItems}</div>
				<p>
					<br />
				</p>
			</>
		);
	}

	fichiersList() {
		const listItems = this.state.fichiers.map(fichier => (
			<div className="col-sm-4" key={fichier.fichier.id}>
				<a style={{ color: "#666" }} href={fichier.fichier.link}>
					<svg fill="#666" width="24" height="24" viewBox="0 0 24 24">
						<path d="M16 13h-3V3h-2v10H8l4 4 4-4zM20 15v4H4V15H2v6H22V15z"></path>
					</svg>
					&nbsp;&nbsp;&nbsp;{fichier.fichier.description}
				</a>
			</div>
		));
		const { t } = this.props;
		return (
			<>
				{this.state.fichiers.length > 0 && (
					<>
						<h1 style={{ textAlign: "center" }}>
							{t("media.fichier-telecharge").toUpperCase()}
						</h1>
						<p>
							<br />
						</p>
					</>
				)}
				<div className="row">{listItems}</div>
				<p>
					<br />
				</p>
			</>
		);
	}

	componentDidUpdate() {
		let contentNode = document.querySelector(".contenu-page");
		if (this.state.pageSite.contenu != null) {
			contentNode.innerHTML = this.state.pageSite.contenu;
		}
	}

	render() {
		const { t } = this.props;
		return (
			<div className="container">
				<div className="contenu-page"></div>
				{this.videoYoutubeList()}
				{this.fichiersList()}
			</div>
		);
	}
}

export default withTranslation()(MediaList);
