import React from "react";
import { withTranslation } from "react-i18next";
import { api } from "@mitim/react-mitim";

import Menu from "./Menu/Menu";

class MenuWrapper extends React.Component {
	state = {
		nomSimpl: ""
	};

	componentDidMount() {
		api.getNomWebsite(this, val => {
			this.setState({ nomSimpl: val.data.nomSimpl.toUpperCase() });
		});
	}

	render() {
		const { t } = this.props;
		return (
			<Menu
				items={[
					// {
					// 	rank: 1,
					// 	link: "/",
					// 	name: "Accueil",
					// 	roles: ["ROLE_PUBLIC"]
					// },
					{
						rank: 2,
						link: "/ce-que-nous-croyons",
						name: "Ce que nous croyons",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 3,
						link: "/programme",
						name: "programme",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 4,
						link: "/media",
						name: "medias",
						roles: ["ROLE_PUBLIC"]
					},
					{
						rank: 4,
						link: "/reunions",
						name: "réunions",
						roles: ["ROLE_EGLISE_REUNIONS_" + this.state.nomSimpl]
					},
					{
						rank: 5,
						link: "/EspacePrive",
						name: "informations",
						roles: ["ROLE_EGLISE_FICHIERS_" + this.state.nomSimpl]
					},
					{
						rank: 6,
						link: "/reunionDirect",
						name: "réunion direct",
						roles: ["ROLE_EGLISE_REU_DIRECT_" + this.state.nomSimpl]
					},
					{
						rank: 7,
						link: "/contact",
						name: "contact",
						roles: ["ROLE_PUBLIC"]
					}
				]}
			/>
		);
	}
}

export default withTranslation()(MenuWrapper);
