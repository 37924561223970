import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { tools } from "@mitim/react-mitim";

class PolitiqueConfidentialite extends Page {
	render() {
		const { t } = this.props;
		return (
			<div className="container p-5">
				{tools.titreSousTitre(
					"Politique de confidentialité et de la protection des données"
				)}
				<p>
					Le site Internet missiontimothee.fr utilise un outil de statistiques
					de visites{" "}
					<a
						className="lien"
						target="_blank"
						href="https://analytics.google.com/analytics/web/"
					>
						Google Analytics
					</a>
				</p>
				<p>
					Les statistiques de visites collectées par Google Analytics
					n'impliquent la transmission d'aucune donnée à caractère personnel,
					elles le sont de façon anonyme.
				</p>
				<h2 className="contenu-h2">Cookies</h2>
				<p>
					<strong>Qu'est-ce qu'un cookie ?</strong> Les cookies sont de petits
					fichiers que des sites web ou des services web (comme des analyseurs
					d'audience) mettent dans le cache de votre navigateur lors de votre
					première visite. Lire une{" "}
					<a
						className="lien"
						target="_blank"
						href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
					>
						description complète des cookies
					</a>{" "}
					sur le site de Microsoft.
				</p>
				<p>
					Ce site utilise des services tiers qui ont besoin d'apposer des
					cookies pour fonctionner. Ces services sont décrites ci-dessous :
				</p>
				<p>Google Analytics</p>
				<p className="mb-5">
					Cookie apposé par le service de{" "}
					<a
						className="lien"
						target="_blank"
						href="http://www.google.com/analytics/"
					>
						Google Analytics
					</a>{" "}
					- système de suivi des visites sur un site web.
				</p>
			</div>
		);
	}
}

export default withTranslation()(PolitiqueConfidentialite);
