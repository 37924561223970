import React from "react";
// import './contactForm.css';
import { api } from "@mitim/react-mitim";

export class ContactForm extends React.Component {
	constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			success: false,
			disableButton: false
		};
	}

	resetFields() {
		document.getElementById("inputName").value = "";
		document.getElementById("inputEmail").value = "";
		document.getElementById("inputMessage").value = "";
	}

	handleSubmit(event) {
		event.preventDefault();
		const disableButton = true;
		this.setState({ disableButton });
		const data = new FormData(event.target);
		var message = {};
		for (var entry of data.entries()) {
			message[entry[0]] = entry[1];
		}
		message = JSON.stringify(message);
		api.sendMessageWithDomain(message, this, window.location.hostname);
	}

	render() {
		return (
			<div className="formulaire">
				<form
					onSubmit={this.handleSubmit}
					name="contactform"
					method="post"
					action
					className="form-horizontal"
				>
					<div className="form-group">
						<div className="col-lg-10">
							<input
								type="text"
								className="form-control"
								id="inputName"
								name="nom"
								placeholder="Votre nom*"
								required
							/>
						</div>
					</div>
					<div className="form-group">
						<div className="col-lg-10">
							<input
								type="email"
								className="form-control"
								id="inputEmail"
								name="adresseEmail"
								placeholder="Votre adresse email*"
								required
							/>
						</div>
					</div>
					<div className="form-group">
						<div className="col-lg-10">
							<textarea
								className="form-control"
								rows={4}
								id="inputMessage"
								name="message"
								placeholder="Votre message*"
								required
								defaultValue={""}
							/>
						</div>
					</div>
					<div
						className="g-recaptcha"
						data-sitekey="6LfqmHIUAAAAAKcNAK0KH_hUO1AzWNlh5sBTEBQe"
					></div>
					<br />
					<div
						className="alert alert-success alert-dismissible fade in"
						hidden={!this.state.success}
					>
						<a
							href="#"
							className="close"
							data-dismiss="alert"
							aria-label="close"
						>
							×
						</a>
						<strong>Votre message à bien été envoyé !</strong>
					</div>

					<button
						type="submit"
						className="btn btn-success"
						disabled={this.state.disableButton}
					>
						Envoyer le message
					</button>
				</form>
			</div>
		);
	}
}
