import React from "react";
import { withTranslation } from "react-i18next";
import { relative } from "path";
import { Link } from "react-router-dom";

class SlideTemplate1 extends React.Component {
	// componentDidMount() {
	//     getPageSite("accueil", this);
	// }
	render() {
		const data = this.props.slide;
		const { t } = this.props;

		return (
			<>
				<div className="col-lg-2 no-gutters">
					{/* <div className="d-none d-lg-block">
						<img
							src={require(`../../resources/icons/icone_oeuvreprotestante.svg`)}
							className="img-fluid mt-5"
							style={{ height: "100px" }}
						/>
					</div> */}
				</div>

				<div className="align-self-center col-12 col-lg-8">
					<div className="text-center mb-5 pt-5">
						<div className="text-white pt-5">
							<h2
								className="ft-parisienne text-white pt-5"
								style={{ color: "#FFF", fontSize: "4em" }}
							>
								{this.props.titre}
							</h2>
						</div>
						{/* <div className="text-center" style={{ marginTop: "-30px" }}>
							<a href="https://www.missiontimothee.fr" target="_blank">
								<img
									src={require(`../../resources/icons/logo_missiontimothee.svg`)}
									className="img-fluid"
									style={{
										maxHeight: "40px",
										transform: "translate(0%, 100%)"
									}}
								/>
							</a>
						</div> */}
					</div>
				</div>

				<div
					className="col-lg-2 no-gutters d-none d-lg-block text-right"
					style={{}}
				></div>
			</>
		);
	}
}
export default withTranslation()(SlideTemplate1);
