// Libraries
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer/Footer";
import BarrePolitique from "./PolitiqueConfidentialite/BarrePolitique";
import HeaderAndRoute from "./HeaderAndRoute";
import Home from "./Home";
import Login from "./Login/Login";
import Inscription from "./Login/Inscription";
import MonCompte from "./Login/MonCompte";
import CompteOublie from "./Login/CompteOublie";
import ChangerMotDePasse from "./Login/ChangerMotDePasse";
import ConnectLink from "./Login/ConnectLink";
import Contact from "./Contact/Contact";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite/PolitiqueConfidentialite";

// import { Sandbox } from "./Sandbox/Sandbox";
// import { PrivateRoute, LoginRoute, ROLE_CANDIDAT } from "./Login/Auth";
import { PrivateRoute, LoginRoute } from "./Login/PrivateHeaders";
import "@mitim/react-mitim/dist/styles.css";

// CSS
import "../styles/App.scss";
import "../styles/Font.scss";
import "../styles/Media.scss";
import CeQueNousCroyons from "./CeQueNousCroyons/CeQueNousCroyons";
import Programme from "./Programme/Programme";
import MediaList from "./MediaList/MediaList";
import Reunions from "./Reunions/Reunions";
import EspacePrive from "./EspacePrive/EspacePrive";
import ReuDirect from "./ReuDirect/ReuDirect";
import { api, Storage } from "@mitim/react-mitim";

$(document).ready(function() {
	/* ---------------------------------------------- /*
		* Scroll top
		/* ---------------------------------------------- */

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$(".scroll-up").fadeIn();
		} else {
			$(".scroll-up").fadeOut();
		}
	});

	$('a[href="#totop"]').click(function() {
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
		return false;
	});

	function receiveMessage(event) {
		// Faisons-nous confiance à l'expéditeur de ce message ?  (il pourrait être
		// différent de la fenêtre que nous avons ouverte au départ, par exemple).
		if (event.origin !== "https://storage.timothee.fr") return;
		// console.log(event.data);
		if (event.data.token !== null) {
			// token existe ?

			if (event.data.cookies === "true") {
				localStorage.setItem("cookies", "true");
			}
			const user = Storage.getUserParsed();
			if (user !== null && user.token === event.data.token) {
				console.log("le token est le même");
			} else {
				console.log("le token est différent");
				loadUserByToken(event.data.token);
			}
		} else {
			// si le token n'existe pas
			console.log("pas de token !");
			const user = Storage.getUserParsed();
			if (user !== null) {
				let packet = {
					methode: "postUser",
					user: user
				};
				iframeStorage.postMessage(packet, "https://storage.timothee.fr");
			}
		}
	}
	window.addEventListener("message", receiveMessage, false);

	var iframeStorage = document.getElementById("iframeStorage").contentWindow; // on récupere l'iframe du storage.timothee.fr
	let packet = {
		methode: "getToken"
	};
	iframeStorage.postMessage(packet, "https://storage.timothee.fr");
});

export default class App extends React.Component {
	state = {
		nomSimpl: ""
	};

	componentDidMount() {
		api.getNomWebsite(this, val => {
			this.setState({ nomSimpl: val.data.nomSimpl.toUpperCase() });
		});
	}

	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						{/* DIVERS *************************************************** */}
						{/* cookies */}
						<HeaderAndRoute
							path="/cookies"
							component={PolitiqueConfidentialite}
							slide={{
								template: 1,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Politique de confidentialité",
								descriptionMeta: "Politique de confidentialité"
							}}
						/>

						{/* Connexion */}
						<LoginRoute
							path="/login"
							component={Login}
							titre="menu.login"
							slide={{
								template: 1,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Page d'authentification",
								descriptionMeta: "Page d'authentification"
							}}
						/>
						<HeaderAndRoute
							path="/changer-mot-de-passe/:token"
							component={ChangerMotDePasse}
							titre="menu.inscription"
							slide={{
								template: 1,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Changement de mot de passe",
								descriptionMeta: "Changement de mot de passe"
							}}
						/>

						<HeaderAndRoute
							path="/connexion/:token"
							component={ConnectLink}
							titre="menu.inscription"
							slide={{
								template: 1,
								image: "Contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Connexion",
								descriptionMeta: "Connexion"
							}}
						/>

						{/* ******************************************************************* */}

						{/* CeQueNousCroyons */}
						<HeaderAndRoute
							path="/ce-que-nous-croyons"
							component={CeQueNousCroyons}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "ceQueNousCroyons.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Ce que nous croyons",
								h2: "",
								h3: "",
								descriptionMeta:
									"Extraits de la confession de foi de la mission Timothée selon la doctrine protestante du réformateur, Jean CALVIN"
							}}
						/>

						{/* Programme */}
						<HeaderAndRoute
							path="/programme"
							component={Programme}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "Programme.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Programme",
								h2: "",
								h3: "",
								descriptionMeta:
									"En 1972 quelques amis, touchés par le désir de servir le Seigneur auprès des délaissés, créent une maison d’accueil sur une colline à Anduze. Elle deviendra la Mission Timothée."
							}}
						/>

						{/* Reunions */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_EGLISE_REUNIONS_" + this.state.nomSimpl]}
							path="/reunions"
							component={Reunions}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "Programme.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Réunions",
								h2: "",
								h3: "",
								descriptionMeta: "Programme"
							}}
						/>

						{/* Reunions */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_EGLISE_REU_DIRECT_" + this.state.nomSimpl]}
							path="/reunionDirect"
							component={ReuDirect}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "reuDirect.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Réunion en direct",
								h2: "",
								h3: "",
								descriptionMeta: "Programme"
							}}
						/>

						{/* EspacePrive */}
						<PrivateRoute
							privateRoute="true"
							authorities={["ROLE_EGLISE_FICHIERS_" + this.state.nomSimpl]}
							path="/EspacePrive"
							component={EspacePrive}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "Programme.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Espace d'information",
								h2: "",
								h3: "",
								descriptionMeta: ""
							}}
						/>

						{/* Medias */}
						<HeaderAndRoute
							path="/media"
							component={MediaList}
							metaTagKeyword="metatag.keywords-home"
							slide={{
								template: 1,
								image: "media.jpg",
								// icone: 'icone_handicap.svg',
								h1: "Medias",
								h2: "",
								h3: "",
								descriptionMeta: "Medias"
							}}
						/>

						{/* CONTACT */}
						<HeaderAndRoute
							path="/contact"
							component={Contact}
							slide={{
								template: 1,
								image: "Contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Contact",
								descriptionMeta:
									"Vous pouvez nous contacter par voie postale, par téléphone ou en envoyant un message via le formulaire."
							}}
						/>
						{/* HOME */}
						<HeaderAndRoute
							path="/"
							slide={{
								num: 0,
								home: true,
								nom: "Page accueil",
								template: 2,
								image: "Farvagny.jpg",
								icone: "icone_maison.svg",
								h1: "Page d'accueil",
								descriptionMeta: "Église de la Mission Timothée"
							}}
							component={Home}
						/>
					</Switch>
					<Footer />
					<BarrePolitique />
					<div className="scroll-up">
						<a href="#totop">
							<i className="fa fa-angle-double-up" />
						</a>
					</div>
				</div>
			</Router>
		);
	}
}

export const loadUserByToken = token => {
	fetch(`${api.timediaUrl}/public/getUser`, {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		},
		method: "GET"
	}).then(response => {
		if (response.status !== 200) {
			console.error("Error: " + response.status);
			return;
		}
		response.json().then(json => {
			var authorities = new Array();
			json.data.authorities.forEach(function(authority) {
				authorities.push(authority.name);
			});
			Storage.setAuthoritiesJson(JSON.stringify(authorities));
			localStorage.setItem("user", JSON.stringify(json.data));
			document.location.reload();
		});
	});
};
