import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api, tools, Icones } from "@mitim/react-mitim";
import "./EspacePrive.scss";

class EspacePrive extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		pageSite: [],
		website: []
	};

	componentDidMount() {
		super.componentDidMount();
		api.getPageSite("espacePrive", this);
	}

	componentDidUpdate() {
		let contentNode = document.querySelector(".contenu-page");
		if (this.state.pageSite.contenu != null) {
			contentNode.innerHTML = this.state.pageSite.contenu;
		}
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid eglises no-gutters pt-5 pb-5"
					style={{ minHeight: "480px" }}
				>
					<div className="contenu-page"></div>
				</div>
			</>
		);
	}
}

export default withTranslation()(EspacePrive);
