import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoutButton } from "../Login/PrivateHeaders";
// import auth, { LogoutButton } from "../Login/Auth";
import { auth } from "@mitim/react-mitim";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import iconLock from '../../resources/icons/lock.png';
import iconLock from "../../resources/icons/icone-lock.png";
import "./Connexion.scss";

function Connexion() {
	const { t, i18n } = useTranslation();
	// Déclaration d'une nouvelle variable d'état, que l'on appellera “count”
	const [count, setCount] = useState(0);

	return (
		<div className="ml-md-auto col-auto">
			{auth.isAuthenticated() ? (
				<span className="connected ml-4" style={{ position: "relative" }}>
					<Link to="/mon-compte" className="lien d-inline">
						<span
							style={{
								transform: "translate(-2px, 2px)"
								// position: "absolute",
								// margin: "5px 0 0 -32px",
								// fontSize: "28px",
								// color: "#009d96"
							}}
						>
							<AccountCircleIcon
								style={{
									// position: "absolute",
									// margin: "5px 0 0 -32px",
									fontSize: "28px",
									color: "#009d96"
								}}
								className="d-inline"
							/>
						</span>
						<span className="" style={{ fontSize: "1em", color: "#009d96" }}>
							{auth.getUser().firstname} {auth.getUser().lastname}
							{/* {auth.getUser().firstname} {auth.getUser().lastname} */}
							{/* {auth.getUser().prenom} {auth.getUser().nom} */}
						</span>
					</Link>
					<LogoutButton
						component={ExitToAppIcon}
						className="cursor-pointer m-1"
						style={{ fontSize: "28px" }}
					/>
				</span>
			) : (
				<span className="auth">
					<Link to="/login">
						<img src={iconLock} alt="" className="mr-2" />
						<span>{t("menu.login")}</span>
					</Link>
				</span>
			)}
		</div>
	);
}
export default withTranslation()(Connexion);
