import React from "react";
import { withTranslation } from "react-i18next";
import BackgroundSlider from "react-background-slider";
import SlideTemplate1 from "./SlideTemplate1";
import SlideTemplate2 from "./SlideTemplate2";
import { tools } from "@mitim/react-mitim";
import { api } from "@mitim/react-mitim";

class Slide extends React.Component {
	componentDidMount() {
		api.getPageSite("accueil", this);
	}
	state = { pageSite: [], website: [], fichiers: [] };

	wallpaper() {
		return (
			<figure
				className="figureReactBackgroundSlider"
				style={{
					backgroundImage: this.props.slide.home
						? "url(" +
						  `${tools.lienStorage}/fichiers_commun/eglises/${this.state.pageSite.lienCouverture}` +
						  ")"
						: "url(" +
						  `${tools.lienStorage}/fichiers_commun/eglises/${this.props.slide.image}` +
						  ")",
					filter: "brightness(60%)",
					maxHeight: "500px",
					// marginTop: "50px",
					backgroundSize: "auto",
					backgroundPositionY: "-50px"
				}}
			/>
		);
	}

	render() {
		const data = this.props.slide;

		const images = this.props.images;
		const { t } = this.props;

		return (
			<section className="slider-background">
				<div id="ReactBackgroundSlider">{this.wallpaper()}</div>

				<div className="container-fluid">
					<div className="row" style={{ marginTop: "50px" }}>
						{this.props.slide.template === 1 && (
							<SlideTemplate1
								slide={this.props.slide}
								titre={this.props.slide.h1}
							/>
						)}
						{this.props.slide.template === 2 && (
							<SlideTemplate2
								slide={this.props.slide}
								titre={this.state.website.nom}
							/>
						)}
					</div>
				</div>
			</section>
		);
	}
}
export default withTranslation()(Slide);
