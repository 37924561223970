import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";

class CeQueNousCroyons extends Page {
	constructor(props) {
		super(props);
		this.state = { website: [] };
	}

	componentDidMount() {
		super.componentDidMount();
		api.getWebsite(this);
	}

	componentDidUpdate() {
		// let contentNode = document.querySelector('.contenu-page');
		// if (this.state.result.contenu != null)
		//     contentNode.innerHTML = this.state.result.contenu;
	}

	render() {
		const { t } = this.props;
		return (
			<div className="confession">
				<div className="main showcase-page">
					<section className="module-medium">
						<div className="container eglises">
							<h1 style={{ textAlign: "center" }}>
								{t("ce-que-nous-croyons.titre")}
							</h1>

							<div id="confession-foi">
								<p>
									<em>
										<Trans i18nKey="ce-que-nous-croyons.extrait">
											Extraits de la confession de foi de la mission Timothée
											selon la doctrine protestante du réformateur, Jean CALVIN.
											Pour la version complète, veuillez cliquer{" "}
											<a href="https://storage.timothee.fr/public_storage/fichiers_commun/MissionTimothee/data/confession%20de%20foi%20MT.pdf">
												ici
											</a>
										</Trans>
									</em>
								</p>
								<p>
									<br />
								</p>
								<Trans i18nKey="ce-que-nous-croyons.01-bible-ecriture-sainte">
									<h2>La Bible, écriture sainte</h2>
									<p>
										Elle est Parole de Dieu, inspirée par le saint Esprit,
										infaillible et éternelle.
									</p>
									<p>
										Il en découle qu’aucune tradition humaine, expérience ou
										nouvelle révélation ne peuvent lui être opposées. Les
										saintes écritures sont notre seul critère de la vérité.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.02-loi">
									<h2>La loi</h2>
									<p>
										Jésus n’est pas venu pour abolir la loi, mais pour
										l’accomplir en satisfaisant à ses exigences par une vie pure
										et Sa mort expiatoire. Jésus en a considérablement renforcé
										l’obligation.
									</p>
									<p>
										La loi morale inscrite dans la conscience des hommes les
										retient de s’abandonner complètement au mal. Elle révèle à
										l’homme son péché, son incapacité à vouloir et pratiquer le
										bien. Elle est le pédagogue qui conduit à Christ.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.03-interpretation-textes-bible">
									<h2>Interprétation des textes de la bible</h2>
									<p>
										L’écriture est elle-même la règle infaillible de
										l’interprétation.
									</p>
									<p>
										La révélation intérieure de l’Esprit de Dieu est nécessaire
										à une compréhension à salut de la Parole divine.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.04-dieu">
									<h2>Dieu</h2>
									<p>
										Dieu est un, Il est esprit, Il est éternel. Il est
										omniscient, omniprésent, omnipotent et souverain. Il est
										saint, fidèle, juste, compatissant et amour. Il est créateur
										de toutes choses, les visibles et les invisibles, y compris
										les anges. Les uns ont persévéré dans l'obéissance. Satan et
										les démons sont déchus et tombés dans la perdition. Satan
										est le Prince de ce monde. Néanmoins, Dieu gouverne et
										conduit toutes choses. Il est seul sage. Dieu possède en
										Lui-même et par Lui-même toute gloire, et toute créature Lui
										doit adoration, service et obéissance.
									</p>
									<p>
										Dans l'unité divine, Il est trois personnes égales, non
										confondues, mais distinctes, d'une seule et même substance,
										puissance et éternité : Dieu le Père, Dieu le Fils et Dieu
										le Saint Esprit.
									</p>
									<ul>
										<li>
											<strong>Le Père</strong>, cause première, principe,
											origine et fin de toutes choses. Le Fils et le Saint
											Esprit Lui sont soumis. Il est le Père des croyants, nos
											prières s'adressent à Lui.
										</li>
										<li>
											<strong>Le Fils, Sa parole</strong> et Sa sagesse
											éternelle. Il est éternellement engendré du Père et non
											créé. Conçu par le Saint Esprit, né de la vierge Marie, Il
											est parfait quant à la divinité et parfait quant à
											l'humanité; Il est vraiment Dieu et vraiment homme, sans
											confusion, sans transformation, sans division, sans
											séparation des natures. Seul médiateur entre Dieu et les
											hommes, Il est mort et ressuscité pour notre salut. Il
											siège comme le Seigneur à la droite du Père, Il est le
											chef de l'Église qui est Son corps. Il revient pour
											l'enlèvement de l'Église, le jugement final et le
											rétablissement du Royaume de Dieu;
										</li>
										<li>
											<strong>Le Saint Esprit</strong>, Sa force, Sa puissance
											efficace. Troisième personne de la trinité, Il inspire la
											Parole [82], nous révèle le Fils, convainc de péché, opère
											le salut dans l'homme, habite chaque croyant pour en faire
											le temple de Dieu, nous conduit dans toute la vérité. Il
											est le consolateur, donne la force au témoignage, dispense
											les dons dans l'Église et accomplit des miracles.
										</li>
									</ul>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.05-homme">
									<h2>L’homme</h2>
									<p>
										L'homme a été créé pour célébrer la gloire de Dieu. Dieu l'a
										créé corps, âme et esprit, conforme à son image, sans la
										moindre tache. Par sa propre faute, il est déchu de la grâce
										dont il jouissait. Ainsi séparé de Dieu, sa nature est
										désormais entièrement corrompue. De ce fait, il est devenu
										incapable de trouver Dieu par son intelligence, sa raison ou
										sa volonté qui sont totalement prisonnières du péché, même
										s'il subsiste encore dans l'homme la pensée de Dieu, de
										l'éternité et un certain discernement du bien et du mal.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.06-oeuvre-salut">
									<h2>L’œuvre du salut</h2>
									<p>
										Malgré cette corruption et cette condamnation générales,
										Dieu, dans Sa grâce, a voulu faire alliance avec l'homme
										déchu.
									</p>
									<p>
										Par Ses seules bontés et Sa miséricorde, Dieu a voulu
										conduire à la gloire beaucoup de fils.
									</p>
									<p>
										Dans Sa volonté éternelle et immuable, Il nous a élus avant
										la fondation du monde en Jésus-Christ, et cela sans
										considération de nos œuvres. Il nous a prédestinés dans Son
										amour à être Ses enfants d'adoption par Jésus-Christ pour
										célébrer la gloire de Sa grâce.
									</p>
									<p>
										En Lui, tout ce qui était nécessaire à notre salut nous a
										été offert et communiqué. Il en est la source, le moyen et
										le but.
									</p>
									<p>
										Dieu a envoyé Son Fils pour satisfaire Sa justice; Sa mort
										nous assure une totale réparation et nous acquitte de tous
										nos péchés. Il est mort une fois pour toutes; Son sacrifice
										expiatoire, Son sang sont nécessaires et pleinement
										suffisants pour nous acquérir la rédemption, le pardon et la
										purification de tous nos péchés.
									</p>
									<p>
										Tout péché mérite la mort; il n'en est aucun qui ne puisse
										être pardonné, hormis le péché contre le Saint Esprit.
									</p>
									<p>
										Jésus-Christ nous a rachetés de la malédiction de la Loi,
										étant devenu malédiction pour nous [135]. Il a effacé l'acte
										rédigé contre nous et dont les ordonnances nous condamnaient
										[136].
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.07-oeuvre-subjective">
									<h3>L'œuvre subjective</h3>
									<p>
										Ceux que Dieu a prédestinés, Il les a aussi appelés par Sa
										Parole [et Son Esprit; Il fait naître en eux la foi [146] et
										la repentance. Dans sa tristesse qui vient de Dieu, la haine
										de son péché et la douleur d'avoir offensé Dieu], le pécheur
										confesse et dénonce son péché, se repent de ses
										transgressions, prie pour son pardon. Il place sa confiance
										dans le Dieu Sauveur, s'approprie la vie de Christ.
									</p>
									<p>
										Par la foi le croyant accepte Christ, se repose en Lui et
										reçoit de Lui seul la justification. La justification est
										une décision judiciaire de Dieu, qui porte à notre compte la
										justice de Christ en réponse à notre foi et non en
										reconnaissance de la justice personnelle de l’homme. Elle a
										pour fondement la résurrection de Jésus d'entre les morts.
									</p>
									<p>
										Lorsque le pécheur se repent, se convertit (Se détourne du
										monde et du péché) et met sa confiance en Jésus-Christ, il
										naît de nouveau, la Parole vivante de Dieu le régénère.
										C'est ce que l'Écriture appelle le baptême du Saint Esprit.
										Il est alors une nouvelle création, Christ habite en lui, il
										devient ainsi le temple de Dieu.
									</p>
									<p>
										Dès notre nouvelle naissance, nous recevons l'Esprit
										d'adoption par lequel nous disons « Père », car ceux qui
										sont conduits par l'Esprit de Dieu sont fils de Dieu.
										L'Esprit Lui-même rend témoignage à notre esprit que nous
										sommes enfants de Dieu.
									</p>
									<p>
										Le Fils, premier-né, devient notre Frère; Il nous ouvre un
										libre accès dans le sanctuaire du Père, nous sommes
										désormais cohéritiers avec Jésus-Christ et scellés pour le
										jour de la rédemption.
									</p>
									<p>
										Notre communion est avec le Père et avec Son Fils
										Jésus-Christ, car celui qui croit au Nom du Fils de Dieu a
										la vie éternelle. Jésus-Christ est le Dieu véritable et la
										vie éternelle.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.08-eglise">
									<h2>Qu'est-ce que l'Église ?</h2>
									<p>
										L'Église est la communauté de tous les croyants de la
										Nouvelle Alliance dont les noms sont inscrits dans les
										cieux. Ils forment une race élue, un peuple que Dieu s'est
										acquis, une nation sainte. Ils sont appelés hors du monde
										par Dieu pour se consacrer à Lui et exercer un sacerdoce
										royal.
									</p>
									<p>
										On devient membre de l'Église par la régénération du Saint
										Esprit, fruit de l'union à Jésus-Christ dans Sa mort et dans
										Sa résurrection. La communion de tous ses membres est avec
										le Père et avec Son Fils Jésus-Christ.
									</p>
									<p>
										L'Église est le corps de Christ ; le Seigneur en est la tête
										et les croyants en sont les membres unis et vivifiés par
										Lui. Elle est l'épouse, objet de Son amour ; Il s'unira avec
										elle lors de Son retour. Elle est le saint temple de Dieu,
										dont le Christ est la pierre angulaire et les enfants de
										Dieu les pierres vivantes s'édifiant sur le fondement des
										apôtres et des prophètes.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.09-culte">
									<h3>Le culte</h3>
									<p>
										Dieu Souverain Seigneur de toutes choses, bon et
										bienfaisant, demande à être cru, aimé, craint, loué,
										invoqué, et servi par les hommes de tout leur cœur, de toute
										leur âme, et de toute leur force. C'est dans ce but que
										l'Église se rassemble pour célébrer le culte.
									</p>
									<p>
										Dieu Lui-même a ordonné et fixé par Sa propre volonté la
										façon de Lui rendre un culte, hors de toute imagination et
										tout désir humains, de toute suggestion de Satan, de toute
										représentation que ce soit. Ce culte doit Lui être rendu en
										esprit et en vérité dans la liberté et la soumission aux
										Écritures. Il n'est pas attaché à un lieu ou à un édifice
										particulier.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.10-ministeres-dons">
									<h3>Les ministères et les dons</h3>
									<p>
										Les ministères s'exercent dans l'Église. Ils sont donnés par
										Dieu pour l'instruction, l'édification, le perfectionnement,
										en vue de son unité et de son accroissement, afin qu'elle
										parvienne à la stature parfaite de Christ.
									</p>
									<p>
										Chaque membre reçoit un ou plusieurs dons de l'Esprit pour
										l'exercice des ministères. Dieu distribue les dons à qui Il
										veut, comme Il le veut et quand Il le veut, mais tout
										croyant est appelé à y aspirer.
									</p>
									<p>
										Tous les ministères ne se trouvent pas nécessairement dans
										chaque Église, certains dépassent le cadre local. Chaque don
										s'exerce dans l'ordre, l'humilité et la soumission mutuelle
										au sein de l'Église et doit être éprouvé et reconnu par
										elle.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.11-bapteme-sainte-cene">
									<h3>Le Baptême et la Sainte Cène</h3>
									<p>
										Le baptême et la Sainte Cène ont été institués par
										Jésus-Christ. Ils sont un ordre du Seigneur, le témoignage
										extérieur nécessaire de notre union avec Christ dans Sa mort
										et Sa résurrection et de notre appartenance à Son corps qui
										est l'Église. Bien que ces signes ne soient pas le véhicule
										de la grâce, ils ne sont pas dépourvus de valeur. Si nous
										les pratiquons dans la foi, Dieu nous accorde réellement,
										par Son Saint Esprit, les bénédictions promises qui y sont
										associées.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.12-sort-homme-apres-mort">
									<h2>Le sort de l’homme après la mort</h2>
									<p>
										L'homme n'est pas maître de son souffle. Il n'a pas le droit
										d'abréger son existence pas plus que celle des autres. À
										cause du péché, il est réservé aux hommes de mourir une
										seule fois, après quoi vient le jugement. Le corps retourne
										à la poussière. L'âme, qui connaît une existence sans fin,
										retourne à Dieu, soit pour la vie soit pour la mort.
									</p>
									<p>
										Contrairement à l'impie, le croyant qui meurt dans le
										Seigneur triomphe de la mort. Il ne soupire pas après la
										mort mais aspire à la vie éternelle. Plein de confiance, il
										préfère quitter ce corps et demeurer auprès du Seigneur.
									</p>
									<p>
										Le croyant régénéré rejoint Jésus dans le paradis.
										Accompagné de ses œuvres, il connaît le bonheur et le repos,
										en attendant l'immortalité, l'adoption et la rédemption du
										corps.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.13-fin-temps">
									<h3>La fin des temps</h3>
									<p>
										La parole prophétique nous instruit de plusieurs manières
										sur les temps à venir. Elle nous exhorte à y prêter une vive
										attention et à nous garder de toute interprétation
										particulière. Elle concerne principalement le retour de
										notre Seigneur Jésus-Christ.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.14-retour-christ-parousie">
									<h3>Le retour de Christ ou parousie</h3>
									<p>
										L'Église, épouse de Christ, attend avec une ferme espérance
										la parousie. Elle prie et agit pour hâter Sa venue. Christ
										revient délivrer les siens, juger le monde pécheur et
										établir Son règne éternel de justice et de paix.
									</p>
									<p>
										Nul ne connaît le jour, ni l'heure, car il viendra comme un
										voleur dans la nuit. Mais des signes précurseurs nous sont
										donnés, afin de nous inciter à veiller et à lever nos têtes
										car la délivrance est proche.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.15-enlevement-eglise">
									<h3>Enlèvement de l'Église</h3>
									<p>
										Au moment fixé par Dieu, en un clin d'œil, à un signal
										donné, le Christ descendra du ciel pour enlever l'Église.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.16-jugement-monde-pecheur">
									<h3>Le jugement du monde pécheur</h3>
									<p>
										Jésus-Christ a vaincu Satan à la croix. Il règne désormais
										dans le ciel, jusqu'à ce qu'Il ait mis tous Ses ennemis sous
										Ses pieds.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.17-jugement-dernier">
									<h3>Le Jugement dernier</h3>
									<p>
										Après les mille ans, le diable, relâché de sa prison pour
										une dernière révolte, sera définitivement vaincu. La terre
										et le ciel disparaîtront et les morts impies ressusciteront
										pour être jugés d'après leurs œuvres. La mort et le séjour
										des morts, ainsi que le diable, seront jetés dans l'étang de
										feu et de soufre.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.18-ciel">
									<h3>Le ciel</h3>
									<p>
										Un nouveau ciel et une nouvelle terre apparaîtront ainsi que
										la nouvelle Jérusalem, épouse et tabernacle de Dieu avec les
										hommes. Toutes choses seront devenues nouvelles. Dieu
										habitera avec les siens et les consolera. Le Seigneur Dieu
										Tout-Puissant sera leur temple ainsi que l'Agneau. La gloire
										de Dieu les éclairera et l'Agneau sera leur flambeau. Ses
										serviteurs, le front marqué de Son nom, verront Sa face, Le
										serviront et régneront aux siècles des siècles.
									</p>
								</Trans>
								<Trans i18nKey="ce-que-nous-croyons.19-conclusion">
									<h2>En conclusion</h2>
									<p>
										Si nous savons ces choses nous sommes heureux pourvu que
										nous les pratiquions (Jean 13 verset 17)
									</p>
								</Trans>
								<p>
									<em>
										<Trans i18nKey="ce-que-nous-croyons.version-complete">
											Pour la version complète, veuillez cliquer{" "}
											<a href="https://storage.timothee.fr/public_storage/fichiers_commun/MissionTimothee/data/confession%20de%20foi%20MT.pdf">
												ici
											</a>
										</Trans>
									</em>
								</p>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default withTranslation()(CeQueNousCroyons);
