import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api, tools, Icones } from "@mitim/react-mitim";
import "./ReuDirect.scss";

class ReuDirect extends Page {
	constructor(props) {
		super(props);
	}

	state = {
		pageSite: [],
		website: []
	};

	componentDidMount() {
		super.componentDidMount();
		api.getPageSite("reunionDirect", this);
	}

	componentDidUpdate() {
		let contentNode = document.querySelector(".contenu-page");
		if (this.state.pageSite.contenu != null) {
			contentNode.innerHTML = this.state.pageSite.contenu;
		}
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<div
					className="container-fluid eglises no-gutters"
					style={{ minHeight: "880px" }}
				>
					<div className="container">
						<h1 className="text-center pt-5 text-uppercase">
							Réunion en direct
						</h1>

						<div className="contenu-page">
							{/* <div className="video-container" style={{ top: "24px" }}> */}
							{/* <iframe width="560" height="315" src="https://www.youtube.com/embed/cKSfUzvNIUk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
							{/* </div> */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(ReuDirect);
