// Librairies
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
// CSS
import "./Footer.scss";
// Images
import TwitterIcon from "../../resources/icons/social/twitter.png";
import TwitterShareIcon from "../../resources/icons/social/twitter-gris.png";
import YouTubeIcon from "../../resources/icons/social/youtube.png";
import FacebookShareIcon from "../../resources/icons/social/facebook-gris.png";
import ShareIcon from "../../resources/icons/social/partage.png";
import MailShareIcon from "../../resources/icons/social/email-gris.png";

function toggleClass(itemClassName, toggleClassName) {
	const items = document.getElementsByClassName(itemClassName);
	const action = items[0].classList.contains(toggleClassName)
		? "remove"
		: "add";
	[].forEach.call(items, elem => elem.classList[action](toggleClassName));
}

class Footer extends React.Component {
	state = {
		socialLinks: [],
		shareLinks: [],
		secondFooterLink: [],
		footerLinks: []
	};

	componentDidMount() {
		this.setState({
			socialLinks: [
				{
					rank: 1,
					link: "https://twitter.com/missiontimothee",
					imgAlt: "Twitter",
					imgSrc: TwitterIcon
				},
				{
					rank: 2,
					link: "https://www.youtube.com/channel/UCauQjr16cmV32eH2bX-EIqQ",
					imgAlt: "Youtube",
					imgSrc: YouTubeIcon
				},
				// {
				// 	rank: 3,
				// 	link: 'https://plus.google.com/+MissiontimotheeFr',
				// 	imgAlt: 'Google+',
				// 	imgSrc: GooglePlusIcon
				// },
				{ rank: 4, link: null, imgAlt: "social.more", imgSrc: ShareIcon }
			],
			shareLinks: [
				{
					rank: 5,
					link: "https://twitter.com/intent/tweet?url=",
					imgAlt: "social.share-page",
					socialNetwork: "Twitter",
					imgSrc: TwitterShareIcon
				},
				// {
				// 	rank: 6,
				// 	link: 'https://plus.google.com/share?url=',
				// 	imgAlt: 'social.share-page',
				// 	socialNetwork: 'Google+',
				// 	imgSrc: GooglePlusShareIcon
				// },
				{
					rank: 7,
					link: "https://www.facebook.com/sharer/sharer.php?u=",
					imgAlt: "social.share-page",
					social_network: "Facebook",
					imgSrc: FacebookShareIcon
				},
				{
					rank: 8,
					link: "mailto:?body=",
					imgAlt: "social.share-page-by-mail",
					imgSrc: MailShareIcon
				}
			],
			secondFooterLink: [
				{ rank: 1, link: null, name: "footer.copyright" },
				{ rank: 2, link: "/cookies", name: "Politique de confidentialité" }

				// { rank: 2, link: 'https://www.missiontimothee.fr', name: 'footer.site' },
				// { rank: 3, link: 'print', name: 'footer.print' },
				// {
				// 	rank: 6,
				// 	link: 'webmaster-suggest',
				// 	name: 'footer.webmaster-suggest'
				// },
				// { rank: 7, link: 'faq', name: 'footer.faq' },
				// { rank: 8, link: 'mt-partners', name: 'footer.mt-partners' }
			],
			footerLinks: [
				// {
				// 	rank: 1,
				// 	name: 'Mission Timothée',
				// 	children: [
				// 		{ rank: 1, link: 'historique', name: 'menu.historique' },
				// 		{
				// 			rank: 2,
				// 			link: 'statuts-associations',
				// 			name: 'menu.statuts-associations'
				// 		},
				// 		{ rank: 3, link: 'confession-foi', name: 'menu.confession-foi' },
				// 		{ rank: 4, link: 'faire-don', name: 'menu.faire-don' }
				// 	]
				// },
				// {
				// 	rank: 2,
				// 	name: 'menu.maison-accueil',
				// 	children: [
				// 		{
				// 			rank: 1,
				// 			link: 'but-maisons-accueil',
				// 			name: 'menu.but-maisons-accueil'
				// 		}
				// 	]
				// },
				// {
				// 	rank: 3,
				// 	name: 'menu.camps',
				// 	children: [
				// 		{ rank: 1, link: 'camps-bibliques', name: 'menu.camps-bibliques' },
				// 		{ rank: 2, link: 'programme-camps', name: 'menu.programme-camps' },
				// 		{
				// 			rank: 3,
				// 			link: 'inscription-camps',
				// 			name: 'menu.inscription-camps'
				// 		}
				// 	]
				// },
				// {
				// 	rank: 4,
				// 	name: 'menu.formation',
				// 	children: [
				// 		{ rank: 1, link: 'presentation', name: 'menu.presentation' },
				// 		{ rank: 2, link: 'espace-candidats', name: 'menu.espace-candidats' } // TODO check if connected
				// 	]
				// },
				// {
				// 	rank: 5,
				// 	name: 'menu.eglises',
				// 	children: [
				// 		{ rank: 1, link: 'france-etranger', name: 'menu.france-etranger' },
				// 		{
				// 			rank: 2,
				// 			link: 'stations-missionnaires',
				// 			name: 'menu.stations-missionnaires'
				// 		},
				// 		{
				// 			rank: 3,
				// 			link: 'nouvelles-missionnaires',
				// 			name: 'menu.nouvelles-missionnaires'
				// 		}
				// 	]
				// },
				// {
				// 	rank: 6,
				// 	name: 'menu.jeunesse',
				// 	children: [
				// 		{ rank: 1, link: 'camps-colonie', name: 'menu.camps-colonie' },
				// 		{ rank: 2, link: 'camps-jeunes', name: 'menu.camps-jeunes' },
				// 		{ rank: 3, link: 'timothee-kids', name: 'menu.timothee-kids' },
				// 		{ rank: 4, link: 'reunions-enfants', name: 'menu.reunions-enfants' } // TODO check if connected
				// 	]
				// },
				// {
				// 	rank: 7,
				// 	name: 'menu.media-ressources',
				// 	children: [
				// 		{ rank: 1, link: 'cantiques', name: 'menu.cantiques' },
				// 		{
				// 			rank: 2,
				// 			link: 'paroles-partagees',
				// 			name: 'menu.paroles-partagees'
				// 		},
				// 		{ rank: 3, link: 'souvenirs', name: 'menu.souvenirs' }
				// 	]
				// },
				// { rank: 8, link: 'contact', name: 'menu.contact' }
			]
		});
	}
	render() {
		const { t } = this.props;

		const footerLinksStr = this.state.footerLinks.map(menuItem => (
			<div
				className={"col-sm-6 col-md-4 col-lg-4 order-" + menuItem.rank}
				key={menuItem.rank}
			>
				<h5>
					{menuItem.children ? (
						t(menuItem.name)
					) : (
						<Link to={menuItem.link}>{t(menuItem.name)}</Link>
					)}
				</h5>
				{menuItem.children && (
					<ul>
						{menuItem.children.map(subMenuItem => (
							<li key={subMenuItem.rank}>
								<Link to={subMenuItem.link}>{t(subMenuItem.name)}</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		));
		const secondfooterLinksStr = this.state.secondFooterLink.map(footerItem => (
			<div className={"col-md-auto"} key={footerItem.rank}>
				{footerItem.link === null ? (
					<span>
						<a
							href="https://www.missiontimothee.fr"
							className=""
							style={{ textDecoration: "none", color: "#fff" }}
						>
							&copy;Mission Timothee{" "}
						</a>{" "}
						2020
						{/* {footerItem.name === "footer.copyright"
							? t(footerItem.name, { current_year: new Date().getFullYear() })
							: t(footerItem.name)} */}
					</span>
				) : (
					<Link className="lien text-white" to={footerItem.link}>
						{t(footerItem.name)}
					</Link>
				)}
			</div>
		));

		const socialLinksStr = this.state.socialLinks.map(socialItem => (
			<div className="col-auto" key={socialItem.rank}>
				{socialItem.link !== null ? (
					<a href={socialItem.link}>
						<img
							style={{ width: "40px", height: "40px" }}
							title={t("social.consult-page", {
								social_network: socialItem.imgAlt
							})}
							alt={socialItem.imgAlt}
							src={socialItem.imgSrc}
						/>
					</a>
				) : (
					<img
						title={t(socialItem.imgAlt)}
						alt={t(socialItem.imgAlt)}
						src={socialItem.imgSrc}
						className="cursor-pointer"
						onClick={() => toggleClass("share-links", "share-links-show")}
					/>
				)}
			</div>
		));

		const shareLinksStr = this.state.shareLinks.map(socialItem => (
			<div className="col-auto share-links" key={socialItem.rank}>
				<a href={socialItem.link + encodeURI(window.location)}>
					<img
						title={t(socialItem.imgAlt, {
							social_network: socialItem.socialNetwork
						})}
						alt={t(socialItem.imgAlt, {
							social_network: socialItem.socialNetwork
						})}
						src={socialItem.imgSrc}
					/>
				</a>
			</div>
		));

		return (
			<div>
				<div className="module-small">
					<div className="container">
						<div className="social row justify-content-center">
							{socialLinksStr}
							{/* <a className="pl-4" href="http://www.fep.asso.fr" target="_blank">
								<img src={FepIcon}/>
							</a> */}
							<div className="w-1000" />
							{shareLinksStr}
						</div>
						<div className="row">{footerLinksStr}</div>
					</div>
				</div>
				<footer className="footer">
					<div className="container-fluid">
						<div className="row">{secondfooterLinksStr}</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default withTranslation()(Footer);
