import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";

class Programme extends Page {
	constructor(props) {
		super(props);
		this.state = { pageSite: [], website: [], fichiers: [] };
	}

	componentDidMount() {
		super.componentDidMount();
		api.getPageSite("programme", this);
		api.getFichiersOfPageSite("programme", this);
	}

	fichiersList() {
		const listItems = this.state.fichiers.map(fichier => (
			<div className="col-sm-4" key={fichier.fichier.id}>
				<a style={{ color: "#666" }} href={fichier.fichier.sharedLink}>
					<svg fill="#666" width="24" height="24" viewBox="0 0 24 24">
						<path d="M16 13h-3V3h-2v10H8l4 4 4-4zM20 15v4H4V15H2v6H22V15z"></path>
					</svg>
					&nbsp;&nbsp;&nbsp;{fichier.fichier.description}
				</a>
			</div>
		));
		const { t } = this.props;
		return (
			<>
				{this.state.fichiers.length > 0 && (
					<>
						<h1 style={{ textAlign: "center" }}>
							{t("programme.fichier-telecharge").toUpperCase()}
						</h1>
						<p>
							<br />
						</p>
					</>
				)}
				<div className="row">{listItems}</div>
				<p>
					<br />
				</p>
			</>
		);
	}

	componentDidUpdate() {
		let contentNode = document.querySelector(".contenu-page");
		if (this.state.pageSite.contenu != null) {
			contentNode.innerHTML = this.state.pageSite.contenu;
		}
	}

	afficheRencontres() {
		const { t } = this.props;
		return (
			<>
				{this.state.website.postalAdresse && (
					<>
						<h2>{t("programme.lieu-rencontre").toUpperCase()}</h2>
						<p>
							<br />
						</p>
					</>
				)}
				<div
					dangerouslySetInnerHTML={{ __html: this.state.website.postalAdresse }}
				/>
				<p>
					<br />
				</p>
			</>
		);
	}

	afficheResponsables() {
		const { t } = this.props;
		return (
			<>
				{this.state.website.responsables && (
					<>
						<h2>{t("programme.responsables").toUpperCase()}</h2>
						<p>
							<br />
						</p>
					</>
				)}
				<div
					dangerouslySetInnerHTML={{ __html: this.state.website.responsables }}
				/>
				<p>
					<br />
				</p>
			</>
		);
	}

	render() {
		const { t } = this.props;
		const AfficheProgramme = props => (
			<>
				{this.state.website.rencontres && (
					<>
						<h1 className="text-center pt-5">
							{props.t("programme.programme-rencontres").toUpperCase()}
						</h1>
						<p>
							<br />
						</p>
					</>
				)}
				<div
					dangerouslySetInnerHTML={{ __html: this.state.website.rencontres }}
				/>
				<p>
					<br />
				</p>
			</>
		);
		return (
			<div className="container eglises pt-5">
				<div className="contenu-page"></div>
				<AfficheProgramme t={t} />
				{/* {this.afficheProgramme()} */}
				{this.fichiersList()}
				{this.afficheRencontres()}
				{this.afficheResponsables()}
			</div>
		);
	}
}

export default withTranslation()(Programme);
