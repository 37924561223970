import React from "react";
import { withTranslation, Trans } from "react-i18next";
import Page from "../Page";
import { api } from "@mitim/react-mitim";
import { ContactForm } from "./ContactForm/ContactForm";

class Contact extends Page {
	constructor(props) {
		super(props);
		this.state = { website: [], pageSite: [] };
	}

	componentDidMount() {
		super.componentDidMount();
		api.getPageSite("contact", this);
	}

	afficheMap() {
		if (this.state.website.postalAdresseMap) {
			return (
				<div className="">
					<iframe
						title="plan"
						height={600}
						style={{ border: "none" }}
						className="container text-center"
						src={
							"https://maps.google.com/maps?q=" +
							this.state.website.postalAdresseMap +
							"&output=embed"
						}
						allowFullScreen
					></iframe>
				</div>
			);
		}
	}

	afficheResponsables() {
		const { t } = this.props;
		return (
			<>
				{this.state.website.responsables && (
					<>
						<h2>{t("programme.responsables").toUpperCase()}</h2>
						<p>
							<br />
						</p>
					</>
				)}
				<div
					dangerouslySetInnerHTML={{ __html: this.state.pageSite.contenu }}
				/>
				<p>
					<br />
				</p>
			</>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<div className="container eglises pt-5 pb-5">
				<center>
					<h1>{t("contact.plan-acces")}</h1>
				</center>
				<center>
					<br />
					<br />
					{this.afficheMap()}
				</center>
				<br />
				<br />
				{this.afficheResponsables()}
				<center>
					<h2>{t("contact.laissez-nous-message")}</h2>
				</center>
				<br />
				<br />
				<ContactForm />
			</div>
		);
	}
}

export default withTranslation()(Contact);
