import React from "react";
//@ts-ignore
import { Link } from "react-router-dom";
import { Storage } from "@mitim/react-mitim";

class BarrePolitique extends React.Component {
	state = {
		visible: true
	};

	render() {
		return (
			<>
				{!Storage.isAcceptCookies() && (
					<div className="container-fluid d-flex bg-blue-fonce fixed-bottom flex-row align-items-center">
						<div className="text-white mt-3 mb-3">
							En poursuivant votre navigation sur ce site, vous acceptez
							l’utilisation de Cookies pour réaliser des statistiques de
							visites.
							<Link to="/cookies" className="pl-2 text-white">
								<u>Plus d'information</u>
							</Link>
						</div>
						<button
							type="button"
							className="btn btn-info ml-3"
							onClick={() => {
								// localStorage.setItem('cookies', 'true');
								Storage.acceptCookiesTrue();
								this.setState({ visible: false });
							}}
						>
							Accepter
						</button>
					</div>
				)}
			</>
		);
	}
}

export default BarrePolitique;
